import { Field } from 'formik'
import React from 'react'

function Textarea({ fieldName, placeholder, error }) {
    return (
        <Field name={fieldName}>
            {({ field, form, meta }) => {
                return (
                    <>
                        <textarea
                        placeholder={placeholder}
                            className='mbottom_1'
                            name={fieldName}
                            rows={10}
                            value={field.value}
                            onChange={field.onChange}
                        ></textarea>
                        {error && <div className='error_message mtop_1'>{error}</div>}</>
                );
            }}
        </Field>
    )
}

export default Textarea