import React, { useCallback, useEffect, useMemo, useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../assets/css/customer-idea-form.module.scss"
import { FiCheck } from "react-icons/fi"
import Select from "../components/form/select/select"
import Form from "../components/form/form"
import { countryCodes } from "../constants/countryCodes"
import YellowBorderButton from "../components/yellowBorderButton/YellowBorderButton"
import Ideas from "../components/ideas/ideas"
import DragPriority from "../components/draggable/draggable"
import {
  Strings,
  __DURATION_OPTIONS__,
  __IDEAS__,
  __dataToBeSent,
  __step,
} from "../constants/strings"
import { apiIndustries } from "../config/api/endpoints/industries"
import { apiCustomerIdea } from "../config/api/endpoints/customerIdea"
import { useSelector } from "react-redux"
import { getMode } from "../redux/slices/modeSlice"
import { getLanguage } from "../redux/slices/languageSlice"
import * as Yup from "yup"
import Input from "../components/form/input"
import { Formik } from "formik"
import Textarea from "../components/form/textarea"
import AppButton from "../components/appButton/appButton"

const style = {
  backgroundColor: "#E5E5E5",
  color: "black",
  fontWeight: "bold",
}

const Step1Schema = Yup.object().shape({
  name: Yup.string().required("Name required"),
  surname: Yup.string().required("Lastname required"),
  email: Yup.string().required("Email required").email("Invalid email"),
  position: Yup.string().required("Position required"),
  company: Yup.string().required("Company required"),
  phone: Yup.string().required("Phone required"),
})

const Step2Schema = Yup.object().shape({
  project_title: Yup.string().required("Project title required"),
  project_description: Yup.string().required("Project description required"),
  reference_project: Yup.string().required("Reference project required"),
})

const CustomerIdeaForm = () => {
  const mode = useSelector(getMode)
  const language = useSelector(getLanguage)
  const [step, setStep] = useState(1)
  const [country, setCountry] = useState("+994")
  const [industries, setIndustries] = useState([])
  const [platforms, setPlatforms] = useState([])
  const [submissionStarted, setSubmissionStarted] = useState(false)

  //STEP 2
  const [platform, setPlatform] = useState()
  const [industry, setIndustry] = useState()
  const [duration, setDuration] = useState()
  const [sortOfFeatures, setSortOfFeatures] = useState([])

  const changePlatform = useCallback((e, str) => {
    e.preventDefault()
    setPlatform(str)
  }, [])

  const [dataToBeSent, setDataToBeSent] = useState({
    email: "",
    name: "",
    surname: "",
    position: "",
    company: "",
    phone: "",
    project_title: "",
    project_description: "",
    reference_project: "",
  })

  useEffect(() => {
    const url = new URLSearchParams(window.location.search)
    let emailParam = url.get("email")
    let projectTitleParam = url.get("project_title")
    const copy = {}
    if (emailParam) copy.email = emailParam
    if (projectTitleParam) copy.project_title = projectTitleParam
    setDataToBeSent({ ...dataToBeSent, ...copy })
  }, [])
  console.log("___STEP", step)
  useEffect(() => {
    const validityArray = Object.values(dataToBeSent).filter(
      elem => !!elem && !!elem.length
    )
    if (validityArray.length) {
      localStorage.setItem(__dataToBeSent, JSON.stringify(dataToBeSent))
    }
  }, [dataToBeSent])

  useEffect(() => {
    if (step < 4) {
      const validityArray = Object.values(dataToBeSent).filter(
        elem => !!elem && !!elem.length
      )
      if (validityArray.length) {
        localStorage.setItem(__step, step)
      }
    } else {
      localStorage.setItem(__step, 1)
      localStorage.setItem(__dataToBeSent, "{}")
    }
  }, [step])

  useEffect(() => {
    try {
      const data = JSON.parse(localStorage.getItem(__dataToBeSent)) || {}
      if (Object.values(data).length > 0) {
        setDataToBeSent(data)
        if (data.industry) setIndustry(data.industry)
        if (data.duration) setDuration(data.duration)
        if (data.platform) setPlatform(data.platform)
      }
      const st = JSON.parse(localStorage.getItem(__step))
      if (st && st < 4) {
        setStep(st)
      }
    } catch (error) {}
  }, [])

  useEffect(() => {
    apiIndustries
      .getAllIndustries(language)
      .then(({ data: { data } }) => {
        const industries = data.map(elem => ({
          title: elem.attributes.name,
          value: elem.id,
        }))
        setIndustries(industries)
      })
      .catch(err => {
        console.log(err)
      })
    apiIndustries
      .getAllPlatforms()
      .then(({ data: { data } }) => {
        const result = data.map(elem => ({
          title: elem.attributes.name,
          value: elem.id,
        }))
        setPlatforms(result)
        if (result.length) {
          setPlatform(result[0].value)
        }
      })
      .catch(err => {
        console.log(err)
      })
    apiIndustries
      .getAllSortOfFeatures()
      .then(({ data: { data } }) => {
        const industries = data.map(elem => ({
          content: elem.attributes.name,
          id: elem.id.toString(),
        }))
        setSortOfFeatures(industries)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  const onSubmit = async e => {
    e.preventDefault()
    if (!submissionStarted) {
      setSubmissionStarted(true)
      const dial_code = countryCodes.find(elem => elem.value === country)?.value
      const sort_of_features = sortOfFeatures
        .map(elem => elem.content)
        .join(", ")
      let data = {
        ...dataToBeSent,
        phone: `${dial_code}${dataToBeSent.phone}`,
        industry,
        duration,
        platform,
        sort_of_features,
      }
      try {
        await apiCustomerIdea.createCustomerIdea({ data })
        setStep(4)
      } catch (e) {
        console.log(e)
      } finally {
        setSubmissionStarted(false)
      }
    }
  }

  const projectDurationOptions = useMemo(() => {
    return __DURATION_OPTIONS__.map(elem => ({
      value: elem.value,
      title: elem.title[language],
    }))
  }, [language])

  const selectedPlatform = useMemo(() => {
    if (platforms && dataToBeSent.platform)
      return platforms.find(elem => elem.value === dataToBeSent.platform)
  }, [platforms, dataToBeSent.platform])

  /////// SHOWN VALUES IN CUSTOM SELECT COMPONENT
  const durationShownValue = useMemo(() => {
    let d = projectDurationOptions.find(elem => elem.value === duration)?.title
    return d
  }, [duration, projectDurationOptions])

  const industryShownValue = useMemo(() => {
    if (!industries.length) return ""
    let title = industries.find(elem => elem.value === industry)?.title
    return title
  }, [industry, industries])
  ////////////////////////////////////////////////////////////////////////
  return (
    <Layout>
      <Seo
        title={`${Strings.customerIdeaForm[language]} - ${Strings.companyName}`}
      />
      <h2>{Strings.customerIdeaForm[language]}</h2>
      <br />
      <br />
      <br />
      <div className={styles.container}>
        <div className={styles.steps}>
          <div className={`${styles.step} mbottom_4`}>
            <div
              className={`  ${styles.step_number} 
                            ${
                              mode === "light"
                                ? "bg_blue border_blue color_white"
                                : "bg_yellow border_yellow"
                            } `}
            >
              {step === 1 ? "1" : <FiCheck />}
            </div>
            <p>{Strings.customerInformation[language]}</p>
          </div>
          <div className={`${styles.step} mbottom_4`}>
            <div
              className={`${styles.step_number} 
                            ${
                              mode === "light" ? "border_blue" : "border_yellow"
                            } 
                            ${
                              step >= 2
                                ? mode === "dark"
                                  ? "bg_yellow"
                                  : "bg_blue color_white"
                                : mode === "dark"
                                ? "color_white"
                                : "color_black"
                            }`}
            >
              {step <= 2 ? "2" : <FiCheck />}
            </div>
            <p>{Strings.projectInformation[language]}</p>
          </div>
          <div className={styles.step}>
            <div
              className={`${styles.step_number} 
                          ${mode === "light" ? "border_blue" : "border_yellow"} 
                          ${
                            step >= 3
                              ? mode === "dark"
                                ? "bg_yellow"
                                : "bg_blue color_white"
                              : mode === "dark"
                              ? "color_white"
                              : "color_black"
                          }`}
            >
              {step <= 3 ? "3" : <FiCheck />}
            </div>
            <p>{Strings.reviewAndSubmit[language]}</p>
          </div>
        </div>
        {step === 1 && (
          <Formik
            initialValues={{
              name: dataToBeSent.name,
              surname: dataToBeSent.surname,
              company: dataToBeSent.company,
              position: dataToBeSent.position,
              phone: dataToBeSent.phone,
              email: dataToBeSent.email,
            }}
            enableReinitialize
            validationSchema={Step1Schema}
            onSubmit={values => {
              setDataToBeSent({ ...dataToBeSent, ...values })
              setStep(2)
            }}
          >
            {({ errors, touched }) => {
              return (
                <Form>
                  <div className="double_input mbottom_2">
                    <div className="double_input_element">
                      <Input
                        error={errors.name && touched.name ? errors.name : null}
                        name="name"
                        placeholder={Strings.firstName[language]}
                      />
                    </div>
                    <div className="double_input_element">
                      <Input
                        error={
                          errors.position && touched.position
                            ? errors.position
                            : null
                        }
                        name="position"
                        placeholder={Strings.position[language]}
                      />
                    </div>
                  </div>
                  <div className="double_input mbottom_2">
                    <div className="double_input_element">
                      <Input
                        error={
                          errors.surname && touched.surname
                            ? errors.surname
                            : null
                        }
                        name="surname"
                        placeholder={Strings.lastName[language]}
                      />
                    </div>
                    <div className="double_input_element">
                      <Input
                        error={
                          errors.company && touched.company
                            ? errors.company
                            : null
                        }
                        name="company"
                        placeholder={Strings.company[language]}
                      />
                    </div>
                  </div>
                  <div className="double_input mbottom_2">
                    <div className="double_input_element">
                      <Input
                        error={
                          errors.email && touched.email ? errors.email : null
                        }
                        name="email"
                        placeholder={Strings.email[language]}
                      />
                    </div>
                  </div>
                  <div className="double_input mbottom_2">
                    <div className="double_input_element">
                      <div className="double_input_element">
                        <Select
                          country
                          setValue={setCountry}
                          value={country}
                          options={countryCodes}
                        />
                      </div>
                      <div className="double_input_element">
                        <Input
                          name="phone"
                          error={
                            errors.phone && touched.phone ? errors.phone : null
                          }
                          placeholder={Strings.phone[language]}
                        />
                      </div>
                    </div>
                    <YellowBorderButton
                      disabled={Object.entries(errors).length > 0}
                      title={Strings.continue[language]}
                    />
                  </div>
                </Form>
              )
            }}
          </Formik>
        )}
        {step === 2 && (
          <Formik
            onSubmit={values => {
              setDataToBeSent({
                ...dataToBeSent,
                ...values,
                platform,
                industry,
                duration,
              })
              setStep(3)
            }}
            initialValues={{
              project_title: dataToBeSent.project_title,
              project_description: dataToBeSent.project_description,
              reference_project: dataToBeSent.reference_project,
            }}
            validationSchema={Step2Schema}
          >
            {({ errors, touched }) => {
              return (
                <Form>
                  <div className={`button_box button_box_${mode}`}>
                    <h3>{Strings.choosePlatform[language]}:</h3>
                    <div
                      className={`button_box_buttons button_box_buttons_${mode}`}
                    >
                      {platforms.map(elem => (
                        <AppButton
                          key={elem.value}
                          style={platform === elem.value ? style : null}
                          onClick={e => changePlatform(e, elem.value)}
                        >
                          {elem.title}
                        </AppButton>
                      ))}
                    </div>
                  </div>
                  <br />
                  <div className="double_input mbottom_1">
                    <div className="double_input_element">
                      {industries.length && (
                        <Select
                          placeholder={Strings.industry[language]}
                          setValue={setIndustry}
                          options={industries}
                          value={industry}
                        />
                      )}
                    </div>
                    <div className="double_input_element">
                      <Input
                        name="project_title"
                        error={
                          errors.project_title && touched.project_title
                            ? errors.project_title
                            : null
                        }
                        placeholder={Strings.projectTitle[language]}
                      />
                    </div>
                  </div>
                  <div className="mbottom_1">
                    <Textarea
                      fieldName="project_description"
                      placeholder={Strings.projectDescription[language]}
                      error={
                        errors.project_description &&
                        touched.project_description
                          ? errors.project_description
                          : null
                      }
                    />
                  </div>
                  <div className="double_input">
                    <div className="double_input_element">
                      <Input
                        name="reference_project"
                        error={
                          errors.reference_project && touched.reference_project
                            ? errors.reference_project
                            : null
                        }
                        placeholder={Strings.referenceProjects["en"]}
                      />
                    </div>
                    <div className="double_input_element">
                      <Select
                        options={projectDurationOptions}
                        value={duration}
                        placeholder={Strings.projectDuration["en"]}
                        setValue={setDuration}
                      />
                    </div>
                  </div>
                  <br />
                  <div className={`button_box button_box_${mode}`}>
                    <h3>{Strings.sortFeatures[language]}:</h3>
                    <div className="button_box_draggables">
                      <DragPriority
                        items={sortOfFeatures}
                        onChange={data => setSortOfFeatures(data)}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="double_input">
                    <YellowBorderButton
                      title={Strings.back[language]}
                      onClick={() => setStep(1)}
                    />
                    <YellowBorderButton title={Strings.continue[language]} />
                  </div>
                </Form>
              )
            }}
          </Formik>
        )}
        {step === 3 && (
          <>
            <form className="dfc form">
              <div className="button_box border_white mbottom_4">
                <div className="dfr mbottom_2">
                  <h3 className="color_white">
                    {Strings.customerInformation[language]}
                  </h3>
                  <AppButton
                    className="bg_blue color_white edit_button"
                    onClick={() => setStep(1)}
                  >
                    {Strings.edit[language]}
                  </AppButton>
                </div>
                <div className="button_box_table_container">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <b>{Strings.firstName[language]}:</b>
                        </td>
                        <td>
                          <p>{dataToBeSent.name}</p>
                        </td>
                        <td>
                          <b>{Strings.position[language]}:</b>
                        </td>
                        <td>
                          <p>{dataToBeSent.position}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>{Strings.lastName[language]}:</b>
                        </td>
                        <td>
                          <p>{dataToBeSent.surname}</p>
                        </td>
                        <td>
                          <b>{Strings.company[language]}:</b>
                        </td>
                        <td>
                          <p>{dataToBeSent.company}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>{Strings.email[language]}:</b>
                        </td>
                        <td>
                          <p>{dataToBeSent.email}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>{Strings.phone[language]}:</b>
                        </td>
                        <td>
                          <p>
                            {
                              countryCodes.find(elem => elem.value === country)
                                ?.value
                            }
                            {dataToBeSent.phone}{" "}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <b>{Strings.position[language]}:</b>
                        </td>
                        <td>
                          <p>{dataToBeSent.position}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>{Strings.company[language]}:</b>
                        </td>
                        <td>
                          <p>{dataToBeSent.company}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="button_box border_white">
                <div className="dfr mbottom_2">
                  <h3 className="color_white">
                    {Strings.projectInformation[language]}
                  </h3>
                  <button
                    className="bg_blue color_white edit_button"
                    onClick={() => setStep(2)}
                  >
                    {Strings.edit[language]}
                  </button>
                </div>
                <div className="button_box_table_container">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <b>{Strings.platform[language]}:</b>
                        </td>
                        <td>
                          <p>{selectedPlatform?.title}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>{Strings.domain[language]}:</b>
                        </td>
                        <td>
                          <p>{industryShownValue}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>{Strings.projectTitle[language]}:</b>
                        </td>
                        <td>
                          <p>{dataToBeSent.project_title}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>{Strings.projectDescription[language]}:</b>
                        </td>
                        <td>
                          <pre>{dataToBeSent.project_description}</pre>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>{Strings.referenceProject[language]}:</b>
                        </td>
                        <td>
                          <p>{dataToBeSent.reference_project}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>{Strings.projectDuration[language]}:</b>
                        </td>
                        <td>
                          <p>{durationShownValue}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>{Strings.sortFeatures[language]}:</b>
                        </td>
                        <td>
                          <pre>
                            {sortOfFeatures.map((elem, index) => (
                              <p key={elem.id}>
                                {index + 1}. {elem.content}
                              </p>
                            ))}
                          </pre>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="double_input mtop_1">
                <YellowBorderButton
                  disabled={submissionStarted}
                  title={Strings.back[language]}
                  onClick={() => setStep(2)}
                />
                <YellowBorderButton
                  title={
                    submissionStarted
                      ? Strings.wait[language]
                      : Strings.submit[language]
                  }
                  onClick={onSubmit}
                />
              </div>
            </form>
          </>
        )}

        {step === 4 && (
          <>
            <form
              className={`button_box button_box_${mode}`}
              style={{ textAlign: "center" }}
            >
              <div className="step_final">
                <h1 style={{ color: mode === "dark" ? "white" : "black" }}>
                  {Strings.thankYouSoMuch[language]}
                </h1>
                <br />
                <p>{Strings.thankyouDesc[language]}</p>
              </div>
            </form>
          </>
        )}
      </div>

      <br />
      <Ideas
        title={Strings.whyCustomerIdeaForm[language]}
        ideaList={__IDEAS__[language]}
      />
    </Layout>
  )
}

export default CustomerIdeaForm
