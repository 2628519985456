export const countryCodes = [
    {
        "title": "🇦🇫 +93",
        "value": "+93",
    },
    {
        "title": "🇦🇽 +358",
        "value": "+358",
    },
    {
        "title": "🇦🇱 +355",
        "value": "+355",
    },
    {
        "title": "🇩🇿 +213",
        "value": "+213",
    },
    {
        "title": "🇦🇴 +244",
        "value": "+244",
    },
    {
        "title": "🇦🇮 +1264",
        "value": "+1264",
    },
    {
        "title": "🇦🇶 +672",
        "value": "+672",
    },
    {
        "title": "🇦🇷 +54",
        "value": "+54",
    },
    {
        "title": "🇦🇼 +297",
        "value": "+297",
    },
    {
        "title": "🇦🇺 +61",
        "value": "+61",
    },
    {
        "title": "🇦🇹 +43",
        "value": "+43",
    },
    {
        "title": "🇦🇿 +994",
        "value": "+994",
    },
    {
        "title": "🇧🇸 +1242",
        "value": "+1242",
    },
    {
        "title": "🇧🇭 +973",
        "value": "+973",
    },
    {
        "title": "🇧🇩 +880",
        "value": "+880",
    },
    {
        "title": "🇧🇧 +1246",
        "value": "+1246",
    },
    {
        "title": "🇧🇾 +375",
        "value": "+375",
    },
    {
        "title": "🇧🇪 +32",
        "value": "+32",
    },
    {
        "title": "🇧🇲 +1441",
        "value": "+1441",
    },
    {
        "title": "🇧🇴 +591",
        "value": "+591",
    },
    {
        "title": "🇧🇦 +387",
        "value": "+387",
    },
    {
        "title": "🇧🇼 +267",
        "value": "+267",
    },
    {
        "title": "🇧🇷 +55",
        "value": "+55",
    },
    {
        "title": "🇮🇴 +246",
        "value": "+246",
    },
    {
        "title": "🇧🇳 +673",
        "value": "+673",
    },
    {
        "title": "🇧🇬 +359",
        "value": "+359",
    },
    {
        "title": "🇧🇫 +226",
        "value": "+226",
    },
    {
        "title": "🇧🇮 +257",
        "value": "+257",
    },
    {
        "title": "🇰🇭 +855",
        "value": "+855",
    },
    {
        "title": "🇨🇲 +237",
        "value": "+237",
    },
    {
        "title": "🇨🇦 +1",
        "value": "+1",
    },
    {
        "title": "🇨🇫 +236",
        "value": "+236",
    },
    {
        "title": "🇹🇩 +235",
        "value": "+235",
    },
    {
        "title": "🇨🇱 +56",
        "value": "+56",
    },
    {
        "title": "🇨🇳 +86",
        "value": "+86",
    },
    {
        "title": "🇨🇴 +57",
        "value": "+57",
    },
    {
        "title": "🇨🇬 +242",
        "value": "+242",
    },
    {
        "title": "🇨🇩 +243",
        "value": "+243",
    },
    {
        "title": "🇨🇰 +682",
        "value": "+682",
    },
    {
        "title": " 🇨🇷 +506",
        "value": "+506",
    },
    {
        "title": "🇨🇮 +225",
        "value": "+225",
    },
    {
        "title": "🇭🇷 +385",
        "value": "+385",
    },
    {
        "title": "🇨🇺 +53",
        "value": "+53",
    },
    {
        "title": "🇨🇾 +357",
        "value": "+357",
    },
    {
        "title": "🇨🇿 +420",
        "value": "+420",
    },
    {
        "title": "🇩🇰 +45",
        "value": "+45",
    },
    {
        "title": "🇩🇯 +253",
        "value": "+253",
    },
    {
        "title": "🇩🇲 +1767",
        "value": "+1767",
    },
    {
        "title": "🇩🇴 +1849",
        "value": "+1849",
    },
    {
        "title": "🇪🇨 +593",
        "value": "+593",
    },
    {
        "title": "🇪🇬 +20",
        "value": "+20",
    },
    {
        "title": "🇸🇻 +503",
        "value": "+503",
    },
    {
        "title": "🇬🇶 +240",
        "value": "+240",
    },
    {
        "title": "🇪🇷 +291",
        "value": "+291",
    },
    {
        "title": "🇪🇪 +372",
        "value": "+372",
    },
    {
        "title": "🇪🇹 +251",
        "value": "+251",
    },
    {
        "title": "🇫🇮 +358",
        "value": "+358",
    },
    {
        "title": "🇫🇷 +33",
        "value": "+33",
    },
    {
        "title": "🇬🇪 +995",
        "value": "+995",
    },
    {
        "title": "🇩🇪 +49",
        "value": "+49",
    },
    {
        "title": "🇬🇷 +30",
        "value": "+30",
    },
    {
        "title": "🇬🇳 +224",
        "value": "+224",
    },
    {
        "title": "🇬🇾 +595",
        "value": "+595",
    },
    {
        "title": "🇭🇹 +509",
        "value": "+509",
    },
    {
        "title": "🇭🇳 +504",
        "value": "+504",
    },
    {
        "title": "🇭🇰 +852",
        "value": "+852",
    },
    {
        "title": "🇭🇺 +36",
        "value": "+36",
    },
    {
        "title": "🇮🇸 +354",
        "value": "+354",
    },
    {
        "title": "🇮🇳 +91",
        "value": "+91",
    },
    {
        "title": "🇮🇩 +62",
        "value": "+62",
    },
    {
        "title": "🇮🇷 +98",
        "value": "+98",
    },
    {
        "title": "🇮🇶 +964",
        "value": "+964",
    },
    {
        "title": "🇮🇪 +353",
        "value": "+353",
    },
    {
        "title": "🇮🇱 +972",
        "value": "+972",
    },
    {
        "title": "🇮🇹 +39",
        "value": "+39",
    },
    {
        "title": "🇯🇵 +81",
        "value": "+81",
    },
    {
        "title": "🇯🇴 +962",
        "value": "+962",
    },
    {
        "title": "🇰🇿 +77",
        "value": "+77",
    },
    {
        "title": "🇰🇪 +254",
        "value": "+254",
    },
    {
        "title": "🇰🇵 +850",
        "value": "+850",
    },
    {
        "title": "🇰🇷 +82",
        "value": "+82",
    },
    {
        "title": "🇰🇼 +965",
        "value": "+965",
    },
    {
        "title": "🇰🇬 +996",
        "value": "+996",
    },
    {
        "title": "🇱🇻 +371",
        "value": "+371",
    },
    {
        "title": "🇱🇧 +961",
        "value": "+961",
    },
    {
        "title": "🇱🇮 +423",
        "value": "+423",
    },
    {
        "title": "🇱🇹 +370",
        "value": "+370",
    },
    {
        "title": "🇲🇽 +52",
        "value": "+52",
    },
    {
        "title": "🇲🇩 +373",
        "value": "+373",
    },
    {
        "title": "🇲🇨 +377",
        "value": "+377",
    },
    {
        "title": "🇲🇦 +212",
        "value": "+212",
    },
    {
        "title": "🇳🇱 +31",
        "value": "+31",
    },
    {
        "title": "🇳🇴 +47",
        "value": "+47",
    },
    {
        "title": "🇵🇰 +92",
        "value": "+92",
    },
    {
        "title": "🇵🇭 +63",
        "value": "+63",
    },
    {
        "title": "🇵🇱 +48",
        "value": "+48",
    },
    {
        "title": "🇵🇹 +351",
        "value": "+351",
    },
    {
        "title": "🇵🇷 +1939",
        "value": "+1939",
    },
    {
        "title": "🇶🇦 +974",
        "value": "+974",
    },
    {
        "title": "🇷🇴 +40",
        "value": "+40",
    },
    {
        "title": "🇷🇺 +7",
        "value": "+7",
    },
    {
        "title": "🇷🇼 +250",
        "value": "+250",
    },
    {
        "title": "🇸🇲 +378",
        "value": "+378",
    },
    {
        "title": "🇸🇦 +966",
        "value": "+966",
    },
    {
        "title": "🇸🇳 +221",
        "value": "+221",
    },
    {
        "title": "🇷🇸 +381",
        "value": "+381",
    },
    {
        "title": "🇸🇨 +248",
        "value": "+248",
    },
    {
        "title": "🇸🇱 +232",
        "value": "+232",
    },
    {
        "title": "🇸🇬 +65",
        "value": "+65",
    },
    {
        "title": "🇸🇰 +421",
        "value": "+421",
    },
    {
        "title": "🇸🇮 +386",
        "value": "+386",
    },
    {
        "title": "🇸🇧 +677",
        "value": "+677",
    },
    {
        "title": "🇸🇴 +252",
        "value": "+252",
    },
    {
        "title": "🇿🇦 +27",
        "value": "+27",
    },
    {
        "title": "🇪🇸 +34",
        "value": "+34",
    },
    {
        "title": "🇱🇰 +94",
        "value": "+94",
    },
    {
        "title": "🇸🇩 +249",
        "value": "+249",
    },
    {
        "title": "🇸🇪 +46",
        "value": "+46",
    },
    {
        "title": "🇨🇭 +41",
        "value": "+41",
    },
    {
        "title": "🇹🇼 +886",
        "value": "+886",
    },
    {
        "title": "🇹🇯 +992",
        "value": "+992",
    },
    {
        "title": "🇹🇿 +255",
        "value": "+255",
    },
    {
        "title": "🇹🇭 +66",
        "value": "+66",
    },
    {
        "title": "🇹🇱 +670",
        "value": "+670",
    },
    {
        "title": "🇹🇳 +216",
        "value": "+216",
    },
    {
        "title": "🇹🇷 +90",
        "value": "+90",
    },
    {
        "title": "🇹🇲 +993",
        "value": "+993",
    },
    {
        "title": "🇺🇬 +256",
        "value": "+256",
    },
    {
        "title": "🇺🇦 +380",
        "value": "+380",
    },
    {
        "title": "🇦🇪 +971",
        "value": "+971",
    },
    {
        "title": "🇬🇧 +44",
        "value": "+44",
    },
    {
        "title": "🇺🇸 +1",
        "value": "+1",
    },
    {
        "title": "🇺🇾 +598",
        "value": "+598",
    },
    {
        "title": "🇺🇿 +998",
        "value": "+998",
    },
    {
        "title": "🇻🇪 +58",
        "value": "+58",
    },
    {
        "title": "🇻🇳 +84",
        "value": "+84",
    },
    {
        "title": "🇾🇪 +967",
        "value": "+967",
    },
    {
        "title": "🇿🇲 +260",
        "value": "+260",
    },
    {
        "title": "🇿🇼 +263",
        "value": "+263",
    }
]