import React from "react"
import { useEffect } from "react"
import { useRef } from "react"
import { useMemo } from "react"
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"
import { useSelector } from "react-redux"
import colors from "../../../constants/colors"
import { getMode } from "../../../redux/slices/modeSlice"
import * as styles from "./select.module.scss"

const Select = ({ value, setValue, options, placeholder }) => {
  const mode = useSelector(getMode)
  const [open, setOpen] = React.useState(false)
  const optionsRef = useRef()

  const clickOption = val => {
    setValue(val)
    setOpen(false)
  }

  useEffect(() => {
    if (open && optionsRef.current) {
      const scrollIndex = options.findIndex(elem => elem.value === value)
      const scrollHeight =
        (scrollIndex + 1) * (39 + (window.innerWidth > 860 ? 20 : 10))
      optionsRef.current.scrollTo({
        top: scrollHeight,
      })
    }
  }, [open])

  const activeTitle = useMemo(() => {
    if (!value) return placeholder
    return options.find(option => option.value === value).title
  }, [value])

  return (
    <div className={styles.select + " " + styles[`select_${mode}`]}>
      <div onClick={() => setOpen(!open)} className={styles.shown}>
        <div>
          {value ? activeTitle : <p style={{ color: "grey" }}>{placeholder}</p>}
        </div>
        {open ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </div>
      <div
        style={{ maxHeight: open ? "" : "0", opacity: open ? "1" : "0" }}
        className={styles.options}
      >
        {options.map((elem, index) => (
          <div
            ref={optionsRef}
            style={{
              background: elem.value === value ? colors.grey : "",
              color: elem.value === value ? colors.black : "",
            }}
            key={elem.value + "_" + elem.title}
            onClick={e => clickOption(elem.value)}
            className={`${styles.option} option_${elem.value}`}
          >
            {elem.title}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Select
