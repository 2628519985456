import React, { Component } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { MdDragIndicator } from "react-icons/md"
import * as styles from "./draggable.module.scss"
import isBrowser from "../../util/isBrowser"
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const grid = 8

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,
  display: "flex",
  alignItems: "center",
  border: "2px solid white",
  borderRadius: 4,
  // change background colour if dragging
  background: isDragging ? "#E5E5E5" : "#242424",
  color: isDragging ? "#242424" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver, itemsLength) => ({
  display: "flex",
  padding: grid,
  width: itemsLength * 68.44 + 16,
})

class DragPriority extends Component {
  constructor(props) {
    super(props)
    this.onDragEnd = this.onDragEnd.bind(this)
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(
      this.props.items,
      result.source.index,
      result.destination.index
    )

    this.props.onChange(items)
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    const direction = isBrowser()
      ? document.body.clientWidth > 700
        ? "horizontal"
        : "vertical"
      : "horizontal"
    return (
      <div className={styles.draggable}>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable" direction={direction}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(
                  snapshot.isDraggingOver,
                  this.props.items.length
                )}
                {...provided.droppableProps}
              >
                {this.props.items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <MdDragIndicator />
                        {item.content}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    )
  }
}

export default DragPriority
